<template>
  <div>
    <router-view
      v-if="currentWorkspace"
      :key="$route.path"
      :currentWorkspace="currentWorkspace"
      :currentCompany="currentCompany"
      :unsubscribeAllChannel="unsubscribeAllChannel"
      :company="company"
      :boardFilterObj="boardFilterObj"
      :mediaSearch="mediaSearch"
      :messageSearch="messageSearch"
      :drawerRight="drawerRight"
      :taskView="taskView"
      :myWorkDateFilter="myWorkDateFilter"
      :myworkSearch="myworkSearch"
      :mediaView="mediaView"
      :boardSearchOptions="boardSearchOptions"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import has from "lodash/has";
import get from "lodash/get";

import DesktopNotificationMixin from "@/mixins/DesktopNotification";

import { pusher } from "@/pusher";
import mixpanel from "@/mixpanel";

import {
  CompanyQuery,
  // Workspaces2Query,
  MembersQuery,
  WorkspaceMembersQuery,
  WorkspaceAvailableUsersQuery,
  UserPermissionsQuery,
  WorkspaceFieldsQuery,
} from "@/gql";
import getUnixTime from "date-fns/getUnixTime";

export default {
  name: "WorkspacesView",
  mixins: [DesktopNotificationMixin],
  props: [
    "boardFilterObj",
    "mediaSearch",
    "messageSearch",
    "drawerRight",
    "taskView",
    "workspaces",
    "myWorkDateFilter",
    "myworkSearch",
    "mediaView",
    "isShowImportStatusBanner",
    "boardSearchOptions",
  ],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    company: {
      cacheKey: "CompanyQuery",
      defaultValue: null,
    },
    workspaces2: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
    reactions: {
      cacheKey: "ReactionsQuery",
      defaultValue: null,
    },
    taskLabels: {
      cacheKey: "TaskLabelsQuery",
      defaultValue: null,
    },
    roles: {
      cacheKey: "RolesQuery",
      defaultValue: null,
    },
    rolePermissions: {
      cacheKey: "RolePermissionsQuery",
      defaultValue: null,
    },
    members: {
      query: MembersQuery,
      cacheKey: "MembersQuery",
      defaultValue: null,
    },
    notifications: {
      cacheKey: "NotificationsQuery",
      defaultValue: null,
    },
    workspaceMembers: {
      query: WorkspaceMembersQuery,
      defaultValue: "",
      variables() {
        return {
          workspace_id: parseInt(this.$route.params.workspace),
        };
      },
      cacheKey() {
        return `WorkspaceMembersQuery:${parseInt(
          this.$route.params.workspace
        )}`;
      },
    },
    workspaceAvailableUsers: {
      defaultValue: null,
      query: WorkspaceAvailableUsersQuery,
      variables() {
        return {
          offset: 0,
          workspace_id: parseInt(this.$route.params.workspace),
        };
      },
      cacheKey() {
        return `WorkspaceAvailableUsersQuery:${parseInt(
          this.$route.params.workspace
        )}`;
      },
    },
    workspaceFields: {
      defaultValue: null,
      query: WorkspaceFieldsQuery,
      variables() {
        return {
          workspace_id: parseInt(this.$route.params.workspace),
        };
      },
      cacheKey() {
        return `WorkspaceFieldsQuery:${parseInt(this.$route.params.workspace)}`;
      },
    },
  },
  beforeCreate() {
    this.$eventBus.$off("updateWorkspace");
  },
  created() {
    this.$eventBus.$on("updateWorkspace", this.updateWorkspace);
    if (this.workspaces) {
      this.workspaces.forEach((workspace) => {
        const data = this.$store.getters.activeInfoTab(workspace.id);
        if (!data) {
          this.$store.dispatch("setInfoSidebarActiveTab", {
            workspaceId: workspace.id,
            flag: "about",
          });
        }
        const memberFlag = this.$store.getters.isMemberExpanded(workspace.id);
        if (!memberFlag) {
          this.$store.dispatch("setMemberExpansionState", {
            workspaceId: workspace.id,
            flag: true,
          });
        }
        const descFlag = this.$store.getters.isDescriptionExpanded(
          workspace.id
        );
        if (!descFlag) {
          this.$store.dispatch("setDescriptionExpansionState", {
            workspaceId: workspace.id,
            flag: false,
          });
        }
        const notificationSettFlag =
          this.$store.getters.isWorkspaceNotificationExpanded(workspace.id);
        if (!notificationSettFlag) {
          this.$store.dispatch(
            "setWorkspaceNotificationSettingExpansionState",
            {
              workspaceId: workspace.id,
              flag: false,
            }
          );
        }
        const activityLogFlag = this.$store.getters.isActivityLogExpanded(
          workspace.id
        );
        if (!activityLogFlag) {
          this.$store.dispatch("setActivityLogExpansionState", {
            workspaceId: workspace.id,
            flag: false,
          });
        }
      });
    }
    this.checkPayActiveCompany();
  },
  mounted() {
    this.$store.dispatch("setLastRoute", this.$route);
    if (this.user) {
      mixpanel.identify(this.user.id);
      mixpanel.people.set({
        $company: this.currentCompany.companyUri,
        $email: this.user.email,
        $first_name: this.user.first,
        $last_name: this.user.last,
        $created: this.user.created_at,
      });
      // this.subscribeUserChannel();
    }
    this.$api
      .query({
        query: CompanyQuery,
        cacheKey: "CompanyQuery",
      })
      .then((response) => {
        this.comapny = response.data.company;
        this.subscribeCompanyChannel();
        this.subscribeUserChannel();
      });
    this.workspaces.forEach((workspace) => {
      this.subscribeWorkspaceChannel(workspace.id);
    });
    // this.$api
    //   .query({
    //     query: Workspaces2Query,
    //     cacheKey: "Workspaces2Query",
    //   })
    //   .then((response) => {
    //     this.workspaces = response.data.workspaces2;
    //     this.workspaces.forEach((workspace) => {
    //       this.subscribeWorkspaceChannel(workspace.id);
    //     });
    //   });
    this.checkRouteAccess();
  },
  beforeDestroy() {
    this.$eventBus.$off("updateWorkspace");
  },
  data() {
    return {
      comapny: null,
    };
  },
  methods: {
    checkRouteAccess() {
      if (
        !this.currentWorkspace.show_messages &&
        this.$route.name == "MessagingView"
      ) {
        if (this.currentWorkspace.show_tasks) {
          this.$router.push({
            name: "BoardView",
            params: {
              company: this.$route.params.company,
              workspace: this.currentWorkspaceId,
            },
          });
        } else if (this.currentWorkspace.show_files) {
          this.$router.push({
            name: "MediaView",
            params: {
              company: this.$route.params.company,
              workspace: this.currentWorkspaceId,
            },
          });
        }
      } else if (
        !this.currentWorkspace.show_tasks &&
        this.$route.name == "BoardView"
      ) {
        if (this.currentWorkspace.show_messages) {
          this.$router.push({
            name: "MessagingView",
            params: {
              company: this.$route.params.company,
              workspace: this.currentWorkspaceId,
            },
          });
        } else if (this.currentWorkspace.show_files) {
          this.$router.push({
            name: "MediaView",
            params: {
              company: this.$route.params.company,
              workspace: this.currentWorkspaceId,
            },
          });
        }
      } else if (
        !this.currentWorkspace.show_files &&
        this.$route.name == "MediaView"
      ) {
        if (this.currentWorkspace.show_tasks) {
          this.$router.push({
            name: "BoardView",
            params: {
              company: this.$route.params.company,
              workspace: this.currentWorkspaceId,
            },
          });
        } else if (this.currentWorkspace.show_messages) {
          this.$router.push({
            name: "MessagingView",
            params: {
              company: this.$route.params.company,
              workspace: this.currentWorkspaceId,
            },
          });
        }
      }
    },
    checkPayActiveCompany() {
      if (
        (this.currentCompany.status !== null ||
          this.currentCompany.status !== undefined) &&
        has(this.currentCompany.status, "isActive") &&
        this.currentCompany.status.isActive === false
      ) {
        this.$router.push({
          name: "Payment-View",
        });
      }
    },
    subscribeUserChannel() {
      const userId = this.user.id;
      const userChannel = pusher.subscribe(`private-user=${userId}`);
      userChannel.unbind_all();
      userChannel.bind("notification-created", this.notificationCreatedHandler);
      userChannel.bind(
        "notifications-updated",
        this.notificationUpdatedHandler
      );
      userChannel.bind(
        "notifications-deleted",
        this.notificationDeletedHandler
      );
      userChannel.bind("workspace-created", this.workspaceCreatedHandler);
      userChannel.bind(
        "workspace-preferred-changed",
        this.workspacePreferredChangedHandler
      );
      userChannel.bind("task-bookmark-added", this.taskBookmarkAddedHandler);
      userChannel.bind(
        "task-bookmark-removed",
        this.taskBookmarkRemovedHandler
      );
      userChannel.bind(
        "task-bookmark-cleared",
        this.taskBookmarkClearedHandler
      );
      userChannel.bind(
        "board-notification-cleared",
        this.boardNotificationClearedHandler
      );
    },
    subscribeCompanyChannel() {
      const companyId = this.company ? this.comapny.id : 0;

      const companyChannel = pusher.subscribe(`presence-company=${companyId}`);
      companyChannel.unbind_all();
      const vm = this;
      companyChannel.bind("pusher:subscription_succeeded", function () {
        vm.updateOnlineMembers(companyChannel);
      });
      companyChannel.bind("pusher:member_added", function () {
        vm.updateOnlineMembers(companyChannel);
      });
      companyChannel.bind("pusher:member_removed", function () {
        vm.updateOnlineMembers(companyChannel);
      });
      companyChannel.bind("company-updated", this.companyUpdatedHandler);
      companyChannel.bind("company-deleted", this.companyDeletedHandler);

      companyChannel.bind("user-updated", this.userUpdatedHandler);
      companyChannel.bind(
        "role-permission-changed",
        this.rolePermissionChangesHandler
      );
      companyChannel.bind("user-role-changed", this.userRoleChangedHandler);
      companyChannel.bind("import-data-start", this.importDataStartHandler);
      companyChannel.bind(
        "board-template-created",
        this.createBoardTemplateHandler
      );
      companyChannel.bind(
        "board-template-updated",
        this.updateBoardTemplateHandler
      );
      companyChannel.bind(
        "import-data-progress",
        this.importDataProgressHandler
      );
      companyChannel.bind(
        "import-data-complated",
        this.importDataCompletionHandler
      );
    },
    subscribeWorkspaceChannel(workspaceId) {
      const companyId = this.currentCompany ? this.currentCompany.companyId : 0;

      const channel = pusher.subscribe(
        `private-company=${companyId}-workspace=${workspaceId}`
      );
      channel.unbind_all();
      channel.bind("workspace-updated", this.workspaceUpdatedHandler);
      channel.bind("workspace-deleted", this.workspaceDeletedHandler);
      channel.bind("workspace-member-added", this.workspaceMemberAddedHandler);
      channel.bind(
        "workspace-member-removed",
        this.workspaceMemberRemovedHandler
      );
      //Message Events
      channel.bind("message-created", this.messageCreatedHandler);
      channel.bind("message-updated", this.messageUpdatedHandler);
      channel.bind("message-deleted", this.messageDeletedHandler);
      channel.bind("message-media-detached", this.messageMediaDetachedHandler);
      channel.bind("message-reaction-added", this.messageReactionAddedHandler);
      channel.bind(
        "message-reaction-removed",
        this.messageReactionRemovedHandler
      );

      //Board Events
      channel.bind("board-updated", this.boardUpdatedHandler);
      channel.bind("card-created", this.cardCreateddHandler);
      channel.bind("user-onboarding-updated", this.userOnboardingUpdated);
      channel.bind("card-updated", this.cardUpdatedHandler);
      channel.bind("card-deleted", this.cardDeletedHandler);
      channel.bind("task-created", this.taskCreateddHandler);
      channel.bind("task-updated", this.taskUpdateddHandler);
      channel.bind("task-deleted", this.taskDeleteddHandler);
      channel.bind("tasks-deleted", this.tasksDeleteddHandler);
      channel.bind("bookmark-created", this.bookmarkCreateddHandler);
      channel.bind("bookmark-updated", this.bookmarkUpdateddHandler);
      channel.bind("bookmark-deleted", this.bookmarkDeleteddHandler);
      channel.bind(
        "bookmark-folder-created",
        this.bookmarkFolderCreateddHandler
      );
      channel.bind(
        "bookmark-folder-updated",
        this.bookmarkFolderUpdateddHandler
      );
      channel.bind(
        "bookmark-folder-deleted",
        this.bookmarkFolderDeleteddHandler
      );
      channel.bind("task-member-attached", this.taskMemberAttachedHandler);
      channel.bind("task-member-detached", this.taskMemberDetachedHandler);
      channel.bind("task-label-attached", this.taskLabelAttachedHandler);
      channel.bind("task-label-detached", this.taskLabelDetachedHandler);
      channel.bind("task-media-attached", this.taskMediaAttachedHandler);
      channel.bind("task-media-detached", this.taskMediaDetachedHandler);
      channel.bind("task-subtask-created", this.subtaskCreatedHandler);
      channel.bind("task-subtask-updated", this.subtaskUpdatedHandler);
      channel.bind("task-subtask-deleted", this.subtaskDeletedHandler);
      channel.bind("task-subtask-cleared", this.subtasksClearedHandler);
      channel.bind(
        "subtask-member-attached",
        this.subTaskMemberAttachedHandler
      );
      channel.bind(
        "subtask-member-detached",
        this.subTaskMemberDetachedHandler
      );
      channel.bind("subtask-media-detached", this.subtaskMediaDetached);
      channel.bind("task-comment-created", this.taskCommentCreatedHandler);
      channel.bind("task-comment-updated", this.taskCommentUpdatedHandler);
      channel.bind("task-comment-deleted", this.taskCommentDeletedHandler);
      channel.bind(
        "task-comment-reaction-added",
        this.taskCommentReactionAddedHandler
      );
      channel.bind(
        "task-comment-reaction-removed",
        this.taskCommentReactionRemovedHadler
      );
      channel.bind(
        "task-comment-media-detached",
        this.taskCommentMediaDetached
      );
      channel.bind("custom-field-created", this.customFieldCreatedHandler);
      channel.bind("custom-field-update", this.customFieldUpdatedHandler);
      channel.bind("custom-field-deleted", this.customFieldDeletedHandler);
      channel.bind(
        "custom-field-option-created",
        this.customFieldOptionCreatedHandler
      );
      channel.bind(
        "custom-field-option-updated",
        this.customFieldOptionUpdatedHandler
      );
      channel.bind(
        "custom-field-option-deleted",
        this.customFieldOptionDeletedHandler
      );
      channel.bind(
        "custom-field-option-selection-created",
        this.customFieldOptionSelectionCreatedHandler
      );
      channel.bind(
        "custom-field-option-selection-updated",
        this.customFieldOptionSelectionUpdatedHandler
      );
      //Media Events
      channel.bind("media-created", this.mediaCreatedHandler);
      channel.bind("media-updated", this.mediaUpdatedHandler);
      channel.bind("media-deleted", this.mediaDeletedHandler);
      channel.bind("media-folder-created", this.mediaFolderCreatedHandler);
      channel.bind("media-folder-deleted", this.mediaFolderDeletedHandler);
      channel.bind("media-folder-updated", this.mediaFolderUpdateddHandler);
      channel.bind(
        "media-comment-point-created",
        this.mediaCommentPointCreatedHandler
      );
      channel.bind(
        "media-comment-point-updated",
        this.mediaCommentPointUpdatedHandler
      );
      channel.bind(
        "media-comment-point-deleted",
        this.mediaCommentPointDeletedHandler
      );
      channel.bind("media-comment-created", this.mediaCommentCreatedHandler);
      channel.bind("media-comment-updated", this.mediaCommentUpdatedHandler);
      channel.bind("media-comment-deleted", this.mediaCommentDeletedHandler);
    },
    activityCreatedHandler(activity) {
      if (
        this.user.id === activity.subject.id ||
        this.user.roles[0].id === 1 ||
        this.user.roles[0].id === 2
      ) {
        const query = this.$api.getQuery(
          `ActivityLogQuery:${this.currentWorkspaceId}`
        );
        if (query.data) {
          query.data.activityLog.unshift(activity);
        }
      }
    },
    updateOnlineMembers(companyChannel) {
      const { members } = companyChannel.members;
      const onlineMembers = Object.assign({}, members);
      this.$store.dispatch("setOnlineMembers", onlineMembers);
    },
    unsubscribeWorkspaceChannel(workspaceId) {
      const companyId = this.currentCompany ? this.currentCompany.companyId : 0;

      pusher.unsubscribe(
        `private-company=${companyId}-workspace=${workspaceId}`
      );
    },
    unsubscribeAllChannel() {
      pusher.allChannels().forEach((channel) => {
        channel.unbind_all();
        pusher.unsubscribe(channel.name);
      });
    },
    companyUpdatedHandler(data) {
      const { socket_id: socketId, object: company } = data;
      if (socketId !== pusher.connection.socket_id) {
        const companyEntity = this.$api.getEntity("company", company.id);
        companyEntity.logo = company.logo;
        companyEntity.name = company.name;
        this.updateCompanyInformation(companyEntity, company.uri);
      }
    },
    companyDeletedHandler(data) {
      const { socket_id: socketId, subject: user } = data;
      if (socketId !== pusher.connection.socket_id) {
        if (user.company.uri === this.activeCompany) {
          this.$store.dispatch("signoutFromCompany", this.activeCompany);
          this.$router.push({
            name: "SigninView",
          });

          //   signOutSpecificCompany(args) {
          // 	this.$store.dispatch("signoutFromCompany", args);
          // 	this.unsubscribeAllChannel();
          // 	this.$router.push({
          // 		name: "SigninView",
          // 	});
          // 	resetState();
          // },
        }
      }
    },
    userUpdatedHandler(data) {
      const { socket_id: socketId, object: user } = data;
      if (socketId !== pusher.connection.socket_id) {
        const userEntity = this.$api.getEntity("user", user.id);
        userEntity.first = user.first;
        userEntity.last = user.last;
        userEntity.username = user.username;
        userEntity.designation = user.designation;
        userEntity.phone = user.phone;
        userEntity.country = user.country;
        userEntity.city = user.city;
        userEntity.timezone = user.timezone;
        userEntity.lang = user.lang;
        userEntity.skype = user.skype;
        userEntity.pic = user.pic;
        userEntity.userStatus = user.user_status;
        userEntity.user_status = user.user_status;
      }
    },
    rolePermissionChangesHandler(data) {
      const { socket_id: socketId, object: rolePermission } = data;
      if (socketId !== pusher.connection.socket_id) {
        const query = this.$api.getQuery("UserPermissionsQuery");
        if (query.data) {
          if (rolePermission.status) {
            query.data.userPermissions.push(rolePermission.permission);
          } else {
            query.data.userPermissions = query.data.userPermissions.filter(
              (p) => p.id !== rolePermission.permission_id
            );
          }
        }
      }
    },
    userRoleChangedHandler(data) {
      const { socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const role = this.$api.getEntity("role", data.details.role_id);
        if (role) {
          const user = this.$api.getEntity("user", data.details.user_ids[0]);
          user.roles.splice(0, 1, role);
          this.$api.query({
            query: UserPermissionsQuery,
            cacheKey: "UserPermissionsQuery",
          });
        }
      }
    },
    importDataStartHandler(data) {
      const { object: historyObj } = data;
      const query = this.$api.getQuery("ImportDataQuery");
      if (!!query.data && query.data.importData) {
        query.data.importData = [historyObj, ...query.data.importData];
      } else {
        query.data.importData = [historyObj];
      }
      this.isShowImportStatusBanner.flag = true;
    },
    createBoardTemplateHandler(data) {
      const { object: boardObj } = data;
      const query = this.$api.getQuery(`BoardTemplateQuery`);

      if (!!query.data && query.data.boardTemplate) {
        query.data.boardTemplate.push(boardObj);
      } else {
        query.data.boardTemplate = [boardObj];
      }
      this.$q.notify({
        classes: "success-notification",
        position: "top-right",
        message: "Board template created successfully.",
        timeout: 2500,
        icon: this.$icons.matAnnouncement,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
    },
    updateBoardTemplateHandler() {
      this.$q.notify({
        classes: "success-notification",
        position: "top-right",
        message: "Template applied. Refresh Heycollab for updates.",
        timeout: 2500,
        icon: this.$icons.matAnnouncement,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
    },
    importDataProgressHandler(data) {
      this.isShowImportStatusBanner.flag = true;
      const { object: historyObj } = data;
      const query = this.$api.getQuery("ImportDataQuery");
      if (!!query.data && query.data.importData) {
        query.data.importData = query.data.importData.map((i) => {
          if (i.id === historyObj.id) {
            i.stage = historyObj.stage === 100 ? 99 : historyObj.stage;
          }
          return i;
        });
      }
    },
    importDataCompletionHandler(data) {
      const { object: historyObj } = data;
      const query = this.$api.getQuery("ImportDataQuery");
      if (!!query.data && query.data.importData) {
        query.data.importData = [
          ...query.data.importData.map((i) => {
            if (i.id === historyObj.id) {
              i.completed = true;
            }
            return i;
          }),
        ];
      }
      setTimeout(() => {
        this.isShowImportStatusBanner.flag = false;
      }, 5000);
      this.isShowImportStatusBanner.importCompleted = true;
    },
    notificationCreatedHandler(data) {
      const { socket_id: socketId, action } = data;
      if (socketId !== pusher.connection.socket_id) {
        const query = this.$api.getQuery("NotificationsQuery");
        const workspace = this.$api.getEntity("workspace", action.scope.id);
        action.scope = workspace;
        let notification;
        if (action.type === "task-comment-created") {
          const task = this.$api.getEntity("task", action.object.task_id);
          // let isUserMensionInTask = task.members.map((m) => m.id).includes(this.user.id);
          // if(!isUserMensionInTask) {
          //   return false;
          // }
          //Get media comment from cache for each media
          if (action.object.media.length > 0) {
            action.object.media = action.object.media.map((m) => {
              const media = this.$api.getEntity("media", m.id);
              if (media) {
                return media;
              } else {
                return { ...m, commentPoint: m.comment_point };
              }
            });
          }

          //Get Taskcomment object from caceh for sharedTaskComment
          if (action.object.shared_task_comment) {
            const sharedComment = task.comments.find(
              (c) =>
                c.id ===
                action.object.shared_task_comment.original_task_comment_id
            );
            action.object.sharedTaskComment = sharedComment;
          }
          action.object.task = task;
          notification = {
            read: false,
            ...data,
          };
        } else if (action.type === "task-member-attached") {
          // const task = this.$api.getEntity("task", action.details.task_id);
          // action.object = task;
          notification = {
            read: false,
            ...data,
          };
        } else if (action.type === "task-created") {
          // const task = this.$api.getEntity("task", action.object.id);
          // action.object = task;
          notification = {
            read: false,
            ...data,
          };
        } else if (action.type === "subtask-member-attached") {
          notification = {
            read: false,
            ...data,
          };
        } else if (action.type === "message-created") {
          if (action.object.media.length > 0) {
            action.object.media = action.object.media.map((m) => {
              const media = this.$api.getEntity("media", m.id);
              if (media) {
                return media;
              } else {
                return { ...m, commentPoint: m.comment_point };
              }
            });
          }
          notification = {
            read: false,
            ...data,
          };
        } else {
          notification = {
            read: false,
            ...data,
          };
        }
        query.data.notifications.unshift(notification);
        if (action.scope.notification_level === "all") {
          if (action.type === "task-comment-created") {
            if (this.is_user_mentioned(this.user, action.object.comment)) {
              this.notificationMessageHandler(data);
            }
          } else if (action.type === "message-created") {
            if (this.is_user_mentioned(this.user, action.object.content)) {
              this.notificationMessageHandler(data);
            }
          } else {
            this.notificationMessageHandler(data);
          }
        }
        if (action.scope.notification_level === "mentions") {
          if (
            action.type === "task-comment-created" &&
            this.is_user_mentioned(this.user, action.object.comment)
          ) {
            this.notificationMessageHandler(data);
          }
          if (
            action.type === "message-created" &&
            this.is_user_mentioned(this.user, action.object.content)
          ) {
            this.notificationMessageHandler(data);
          }
        }
      }
    },
    is_user_mentioned(user, string) {
      const regex = new RegExp("(^|\\s)@" + user.username + "($|\\s)");
      const regexForAll = new RegExp("(^|\\s)@all($|\\s)");
      return regex.test(string) || regexForAll.test(string);
    },
    notificationUpdatedHandler(data) {
      const { socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const query = this.$api.getQuery("NotificationsQuery");
        query.data.notifications.map((n) => {
          if (data.details.notification_ids.includes(n.id)) {
            n.read = !n.read;
          }
        });
      }
    },
    notificationDeletedHandler(data) {
      const { socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const query = this.$api.getQuery("NotificationsQuery");
        query.data.notifications = query.data.notifications.filter((n) => {
          if (data.details.notification_ids.includes(n.id)) {
            return;
          }
          return n;
        });
      }
    },
    workspaceCreatedHandler(data) {
      const { socket_id: socketId, object: workspaceObj } = data;
      this.subscribeWorkspaceChannel(workspaceObj.id);
      if (workspaceObj.folder.length && workspaceObj.is_imported) {
        const folderQuery = this.$api.getQuery("FoldersQuery");
        const folderIds = folderQuery.data.folder.map((f) => f.id);
        // Add folder if not added
        if (!folderIds.includes(workspaceObj.folder[0].id)) {
          if (folderQuery.data.folder.length > 1) {
            folderQuery.data.folder = [
              ...folderQuery.data.folder,
              ...workspaceObj.folder,
            ];
          } else {
            folderQuery.data.folder = [...workspaceObj.folder];
          }
        }

        const query = this.$api.getQuery("Workspaces2Query");
        const owner = this.user;
        const workspace = {
          is_preferred: false,
          folder: [...workspaceObj.folder],
          owner,
          ...workspaceObj,
        };
        // this.workspaces.push(workspace);
        query.data.workspaces2.push(workspace);
      } else if (socketId !== pusher.connection.socket_id) {
        const query = this.$api.getQuery("Workspaces2Query");
        if (workspaceObj.type === "people") {
          const user = workspaceObj.users.filter((u) => u.id !== this.user.id);
          workspaceObj.title = user[0].fullname;
          const workspace = {
            description: null,
            is_preferred: false,
            folder: [],
            ...workspaceObj,
          };
          // this.workspaces.push(workspace);
          query.data.workspaces2.push(workspace);
        } else {
          const owner = workspaceObj.users.filter(
            (u) => u.id === workspaceObj.user_id
          );
          const workspace = {
            is_preferred: false,
            folder: [],
            owner,
            ...workspaceObj,
          };
          // this.workspaces.push(workspace);
          query.data.workspaces2.push(workspace);
        }
      }
    },
    workspaceUpdatedHandler(data) {
      const { socket_id: socketId, object: workspace } = data;
      if (socketId !== pusher.connection.socket_id) {
        const workspaceEntity = this.$api.getEntity("workspace", workspace.id);
        workspaceEntity.type = workspace.type;
        workspaceEntity.title = workspace.title;
        workspaceEntity.description = workspace.description;
      }
    },
    workspaceDeletedHandler(data) {
      const { socket_id: socketId, object: workspace } = data;
      if (socketId !== pusher.connection.socket_id) {
        const query = this.$api.getQuery("Workspaces2Query");
        query.data.workspaces2 = query.data.workspaces2.filter(
          (w) => w.id !== workspace.id
        );
        // this.workspaces = this.workspaces.filter((w) => w.id !== workspace.id);
        this.unsubscribeWorkspaceChannel(workspace.id);
        const notificationsQuery = this.$api.getQuery("NotificationsQuery");
        notificationsQuery.data.notifications =
          notificationsQuery.data.notifications.filter((n) => {
            if (
              n.action.scope_type == "App\\Workspace" &&
              n.action.scope_id == workspace.id
            ) {
              return;
            } else {
              return n;
            }
          });
        if (Number(this.$route.params.workspace) === workspace.id) {
          this.$router.push({
            name: "BoardView",
            params: { company: this.currentCompany.companyUri, workspace: 1 },
          });
        }
      }
    },
    workspaceMemberAddedHandler(data) {
      const { socket_id: socketId, object: member } = data;
      if (socketId !== pusher.connection.socket_id) {
        const newMember = this.$api.getEntity("user", member.id);
        if (this.user.id === member.id) {
          //Create workspace
          this.subscribeWorkspaceChannel(data.scope_id);
          const query = this.$api.getQuery("Workspaces2Query");
          const workspace = {
            is_preferred: false,
            ...data.scope,
          };
          // this.workspaces.push(workspace);
          query.data.workspaces2.push(workspace);
        } else {
          //Add newMember to worksaceMembersQuery's cache
          const worksaceMembersQuery = this.$api.getQuery(
            `WorkspaceMembersQuery:${data.scope_id}`
          );
          if (worksaceMembersQuery.data) {
            const { workspaceMembers } = worksaceMembersQuery.data;
            if (workspaceMembers && workspaceMembers.length) {
              worksaceMembersQuery.data.workspaceMembers.push(newMember);
            } else {
              worksaceMembersQuery.data.workspaceMembers = [newMember];
            }
          }
          //Remove newMember from WorkspaceAvailableUsersQuery's cache
          const workspaceAvailableUsersQuery = this.$api.getQuery(
            `WorkspaceAvailableUsersQuery:${data.scope_id}`
          );
          if (workspaceAvailableUsersQuery.data) {
            workspaceAvailableUsersQuery.data.workspaceAvailableUsers =
              workspaceAvailableUsersQuery.data.workspaceAvailableUsers.filter(
                (m) => m.id !== member.id
              );
          }
          //Add user to Workspaces2Query if user is not there
          const workspacesQuery = this.$api.getQuery("Workspaces2Query");
          const workspace = workspacesQuery.data.workspaces2.find(
            (w) => w.id === data.scope_id
          );
          if (!workspace.users.some((u) => u.id === member.id)) {
            workspace.users.push(newMember);
          }
          //Add user to Workspace Entity if user is not there
          const workspaceEntity = this.$api.getEntity(
            "workspace",
            data.scope_id
          );
          if (!workspaceEntity.users.some((u) => u.id === member.id)) {
            workspaceEntity.users.push(newMember);
          }
        }
        this.$api.getQuery("Workspaces2Query");
      }
    },
    workspaceMemberRemovedHandler(data) {
      const { socket_id: socketId, object: member } = data;
      if (socketId !== pusher.connection.socket_id) {
        if (this.user.id === member.id) {
          //delete workspace
          const query = this.$api.getQuery("Workspaces2Query");
          query.data.workspaces2 = query.data.workspaces2.filter(
            (w) => w.id !== data.scope_id
          );
          this.unsubscribeWorkspaceChannel(data.scope_id);
          this.$router.push({
            name: "BoardView",
            params: { comapny: this.currentCompany.companyUri, workspace: 1 },
          });
        } else {
          //remove member
          const query = this.$api.getQuery("Workspaces2Query");
          const worksaceMembersQuery = this.$api.getQuery(
            `WorkspaceMembersQuery:${data.scope_id}`
          );
          const workspaceAvailableUsersQuery = this.$api.getQuery(
            `WorkspaceAvailableUsersQuery:${data.scope_id}`
          );
          if (worksaceMembersQuery.data) {
            const workspace = query.data.workspaces2.find(
              (w) => w.id === data.scope_id
            );
            worksaceMembersQuery.data.workspaceMembers =
              worksaceMembersQuery.data.workspaceMembers.filter(
                (m) => m.id !== member.id
              );
            workspace.users = workspace.users.filter((u) => u.id !== member.id);
            const user = this.$api.getEntity("user", member.id);
            workspaceAvailableUsersQuery.data.workspaceAvailableUsers.push(
              user
            );
          }
        }
      }
    },
    workspacePreferredChangedHandler(data) {
      const { socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const workspace = this.$api.getEntity(
          "workspace",
          data.details.workspace_id
        );
        if (workspace) {
          workspace.is_preferred = !workspace.is_preferred;
        }
      }
    },
    messageCreatedHandler(data) {
      const {
        socket_id: socketId,
        scope_id: workspaceId,
        subject_id: userId,
        object: message,
      } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        if (this.currentWorkspaceId === workspaceId) {
          const query = this.$api.getQuery(`MessagesQuery:${workspaceId}`);
          const user = this.$api.getEntity("user", userId);
          if (query.data) {
            if (data.object.media.length > 0) {
              data.object.media = data.object.media.map((m) => {
                const media = this.$api.getEntity("media", m.id);
                return media;
              });
            }
            if (message.forwardedpost) {
              const forwardedPost = this.$api.getEntity(
                "post",
                message.forwardedpost.original_post_id
              );
              forwardedPost.owner = forwardedPost.user;
              const createdMessage = {
                can_delete: null,
                forwardedPost,
                is_bot: false,
                seens: [],
                user,
                ...message,
              };
              this.$api.updateEntity("post", message.id, createdMessage);
              query.data.messages.unshift(createdMessage);
            } else {
              const createdMessage = {
                can_delete: null,
                forwardedPost: null,
                is_bot: false,
                seens: [],
                user,
                ...message,
              };
              this.$api.updateEntity("post", message.id, createdMessage);
              query.data.messages.unshift(createdMessage);
            }
          }
        }
        this.scrollToLatest("#scroll-container");
      }
    },
    messageUpdatedHandler(data) {
      const { socket_id: socketId, object_id: Id, subject_id: userId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const message = this.$api.getEntity("post", Id);
        if (message) {
          const user = this.$api.getEntity("user", userId);
          if (data.object.media.length > 0) {
            data.object.media = data.object.media.map((m) => {
              const media = this.$api.getEntity("media", m.id);
              return media;
            });
          }
          message.content = data.object.content;
          message.media = data.object.media;
          const updatedMessage = {
            user,
            ...message,
          };
          this.$api.updateEntity("post", Id, updatedMessage);
        }
      }
    },
    messageDeletedHandler(data) {
      const {
        socket_id: socketId,
        scope_id: workspaceId,
        object_id: Id,
      } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const query = this.$api.getQuery(`MessagesQuery:${workspaceId}`);
        console.log("query.data", query);
        if (query.data) {
          const notificationsQuery = this.$api.getQuery("NotificationsQuery");
          notificationsQuery.data.notifications =
            notificationsQuery.data.notifications.filter(
              (n) => n.action.object_id !== Id
            );
          query.data.messages = query.data.messages.filter(
            (post) => post.id !== Id
          );
        }
      }
    },
    messageReactionAddedHandler(data) {
      const { socket_id: socketId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const post = this.$api.getEntity("post", data.details.id);
        const newReactiondata = { ...data.object.reactiondata };
        const query = this.$api.getQuery("NotificationsQuery");
        if (query.data) {
          query.data.notifications.map((n) => {
            if (n.action.object_id === post.id) {
              n.action.object.reactiondata = { ...newReactiondata };
            }
          });
        }
        this.$api.updateEntity("post", post.id, {
          reactiondata: newReactiondata,
        });
      }
    },
    messageReactionRemovedHandler(data) {
      const { socket_id: socketId, subject_id: userId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const reaction = this.$api.getEntity(
          "reaction",
          data.details.reaction_id
        );
        const post = this.$api.getEntity("post", data.details.id);
        // const user = this.$api.getEntity("user", userId);
        const newReactiondata = { ...post.reactiondata };
        if (newReactiondata[reaction.name].length > 1) {
          let index = newReactiondata[reaction.name].findIndex(
            (a) => a.id === userId
          );
          newReactiondata[reaction.name].splice(index, 1);
        } else {
          delete newReactiondata[reaction.name];
        }
        this.$api.updateEntity("post", post.id, {
          reactiondata: newReactiondata,
        });
      }
    },
    messageMediaDetachedHandler(data) {
      const { socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const message = this.$api.getEntity("post", data.details.post_id);
        if (message) {
          message.media = message.media.filter(
            (m) => m.id !== data.details.media[0]
          );
        }
      }
    },
    taskBookmarkAddedHandler(data) {
      const { socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const task = this.$api.getEntity("task", data.details.task_id);
        if (task) {
          task.isbookmarked = !task.isbookmarked;
        }
      }
    },
    taskBookmarkRemovedHandler(data) {
      const { socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const task = this.$api.getEntity("task", data.details.task_id);
        if (task) {
          task.isbookmarked = !task.isbookmarked;
        }
      }
    },
    taskBookmarkClearedHandler(data) {
      const { socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        data.details.task_ids.forEach((id) => {
          const task = this.$api.getEntity("task", id);
          task.isbookmarked = false;
        });
      }
    },
    boardNotificationClearedHandler(data) {
      const { socket_id: socketId, workspace_id: workspaceId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const workspace = this.$api.getEntity("workspace", workspaceId);
        const board = this.$api.getEntity("board", workspace.boards[0].id);
        board.tasks = board.tasks.map((t) => {
          t.notification = [];
          return t;
        });
      }
    },
    boardUpdatedHandler(data) {
      const { socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const board = this.$api.getEntity("board", data.object_id);
        if (board) {
          board.gradient = data.object.gradient;
        }
      }
    },
    cardCreateddHandler(data) {
      const { object: card, object_id: Id, socket_id: socketId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const query = this.$api.getQuery(`BoardQuery:${data.object.board_id}`);
        const createdCard = {
          __typename: "card",
          board_id: card.board_id,
          id: Id,
          sequence: card.sequence,
          title: card.title,
          user_id: card.user_id,
        };
        this.$api.updateEntity("card", Id, createdCard);
        query.data.board.cards.push(createdCard);
      }
    },
    userOnboardingUpdated(data) {
      const { object: user, socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const query = this.$api.getQuery(`UserQuery`);
        query.data.user.onboarding = user;
      }
    },
    cardUpdatedHandler(data) {
      this.activityCreatedHandler(data);
      const { object_id: Id, socket_id: socketId, object: card } = data;
      if (socketId !== pusher.connection.socket_id) {
        //const query = this.$api.getQuery(`BoardQuery:${card.board_id}`);
        const updatedCard = {
          board_id: card.board_id,
          id: Id,
          sequence: card.sequence,
          title: card.title,
          user_id: card.user_id,
          is_lock: card.is_lock,
          is_invisible: card.is_invisible,
        };
        
        // const { cards } = query.data.board;
        // console.log("cardscards", cards);
        // const index = cards.findIndex((c) => c.id === Id);
        // cards.splice(index, 0, { ...updatedCard });

        this.$api.updateEntity("card", updatedCard.id, updatedCard);
      }
    },
    cardDeletedHandler(data) {
      const { object: card, object_id: Id, socket_id: socketId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const query = this.$api.getQuery(`BoardQuery:${card.board_id}`);
        query.data.board.cards = query.data.board.cards.filter(
          (c) => c.id !== Id
        );
      }
    },
    bookmarkCreateddHandler(data) {
      const { socket_id: socketId, object: bookmark } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const createBookmark = {
          __typename: "bookmark",
          ...data.object,
        };
        this.$api.updateEntity("bookmark", data.object_id, createBookmark);
        const query = this.$api.getQuery(
          `BookmarksQuery:${bookmark.workspace_id}`
        );
        if (query.data) {
          query.data.bookmarks.push(createBookmark);
        }
      }
    },
    bookmarkUpdateddHandler(data) {
      const { object: bookmark, socket_id: socketId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const bookmarkEntity = this.$api.getEntity(`bookmark`, bookmark.id);
        if (bookmarkEntity) {
          bookmarkEntity.title = bookmark.title;
          bookmarkEntity.link = bookmark.link;
          bookmarkEntity.user_id = bookmark.user_id;
          bookmarkEntity.workspace_id = bookmark.workspace_id;
        }
      }
    },
    bookmarkDeleteddHandler(data) {
      const { object: bookmark, socket_id: socketId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const query = this.$api.getQuery(
          `BookmarksQuery:${bookmark.workspace_id}`
        );
        if (query.data) {
          query.data.bookmarks = query.data.bookmarks.filter(
            (b) => b.id !== bookmark.id
          );
        }
        const notificationsQuery = this.$api.getQuery("NotificationsQuery");
        notificationsQuery.data.notifications =
          notificationsQuery.data.notifications.filter((n) => {
            if (n.action.object_id !== bookmark.id && n.action.object) {
              return n;
            }
          });
      }
    },
    bookmarkFolderCreateddHandler(data) {
      const { socket_id: socketId, object: bookmarkFolder } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const createBookmarkFolder = {
          __typename: "bookmarkFolder",
          ...data.object,
        };
        this.$api.updateEntity(
          "bookmarkFolder",
          data.object_id,
          createBookmarkFolder
        );
        const query = this.$api.getQuery(
          `BookmarkFoldersQuery:${bookmarkFolder.workspace_id}`
        );
        if (query.data) {
          query.data.bookmarkFolders.push(createBookmarkFolder);
        }
      }
    },
    bookmarkFolderUpdateddHandler(data) {
      const { object: bookmarkFolder, socket_id: socketId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const bookmarkFolderEntity = this.$api.getEntity(
          `bookmarkFolder`,
          bookmarkFolder.id
        );
        if (bookmarkFolderEntity) {
          bookmarkFolderEntity.title = bookmarkFolder.title;
          bookmarkFolderEntity.user_id = bookmarkFolder.user_id;
          bookmarkFolderEntity.workspace_id = bookmarkFolder.workspace_id;
        }
      }
    },
    bookmarkFolderDeleteddHandler(data) {
      const { object: bookmarkFolder, socket_id: socketId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const query = this.$api.getQuery(
          `BookmarkFoldersQuery:${bookmarkFolder.workspace_id}`
        );
        if (query.data) {
          query.data.bookmarkFolders = query.data.bookmarkFolders.filter(
            (b) => b.id !== bookmarkFolder.id
          );
        }
        const notificationsQuery = this.$api.getQuery("NotificationsQuery");
        notificationsQuery.data.notifications =
          notificationsQuery.data.notifications.filter((n) => {
            if (n.action.object_id !== bookmarkFolder.id && n.action.object) {
              return n;
            }
          });
      }
    },
    taskCreateddHandler(data) {
      const { subject_id: userId, socket_id: socketId, object: task } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const owner = this.$api.getEntity("user", userId);
        if (data.object.labels) {
          data.object.labels[0].color = data.object.labels[0].colorcode;
        }
        const cratedTask = {
          __typename: "task",
          end_date: null,
          owner,
          members: [],
          subtasks: [],
          ...data.object,
        };
        this.$api.updateEntity("task", data.object_id, cratedTask);
        const query = this.$api.getQuery(`BoardQuery:${task.board_id}`);
        if (query.data) {
          query.data.board.tasks.push(cratedTask);
        }
      }
    },
    taskUpdateddHandler(data) {
      const { object: task, socket_id: socketId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        console.log("data", data);
        const taskEntity = this.$api.getEntity(`task`, task.id);
        if (taskEntity) {
          taskEntity.title = task.title;
          taskEntity.details = task.details;
          taskEntity.end_date = task.end_date;
          taskEntity.sequence = task.sequence;
          taskEntity.card_id = task.card_id;
          taskEntity.cover_image = task.cover_image;
          taskEntity.is_lock = task.is_lock;
          taskEntity.is_invisible = task.is_invisible;
          // completed drag remaining
          if (taskEntity.card_id !== task.card_id) {
            taskEntity.completed = task.completed ? false : true;
          } else {
            taskEntity.completed = task.completed ? true : false;
          }
        }
      }
    },
    taskDeleteddHandler(data) {
      const { object: task, socket_id: socketId } = data;
      const activityLogQuery = this.$api.getQuery(
        `ActivityLogQuery:${this.currentWorkspaceId}`
      );
        
      activityLogQuery.data.activityLog = activityLogQuery.data.activityLog.filter(
        (t) => t.object_id !== task.id 
      );

      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const card = this.$api.getEntity("card", task.card_id);
        const query = this.$api.getQuery(`BoardQuery:${card.board_id}`);
        if (query.data) {
          query.data.board.tasks = query.data.board.tasks.filter(
            (t) => t.id !== task.id
          );
        }
        const notificationsQuery = this.$api.getQuery("NotificationsQuery");
        notificationsQuery.data.notifications =
          notificationsQuery.data.notifications.filter((n) => {
            if (
              n.action.object_id !== task.id &&
              n.action.object &&
              n.action.object.task_id !== task.id
            ) {
              return n;
            }
          });
      }
    },
    tasksDeleteddHandler(data) {
      const { socket_id: socketId, details } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const card = this.$api.getEntity("card", details.card_id);
        const query = this.$api.getQuery(`BoardQuery:${card.board_id}`);
        if (query.data) {
          query.data.board.tasks = query.data.board.tasks.filter(
            (t) => !details.task_ids.includes(t.id)
          );
        }
      }
    },
    taskMemberAttachedHandler(data) {
      const { socket_id: socketId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const task = this.$api.getEntity("task", data.details.task_id);
        if (task) {
          const member = this.$api.getEntity("user", data.details.user_id);
          task.members.push(member);
        }
      }
    },
    taskMemberDetachedHandler(data) {
      const { socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const task = this.$api.getEntity("task", data.details.task_id);
        if (task) {
          task.members = task.members.filter(
            (m) => m.id !== data.details.user_id
          );
        }
      }
    },
    taskLabelAttachedHandler(data) {
      const { socket_id: socketId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const task = this.$api.getEntity("task", data.details.task_id);
        const label = this.$api.getEntity("label", data.details.label_id);
        if (task) {
          task.labels.push(label);
        }
      }
    },
    taskLabelDetachedHandler(data) {
      const { socket_id: socketId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const task = this.$api.getEntity("task", data.details.task_id);
        if (task) {
          task.labels = task.labels.filter(
            (l) => l.id !== data.details.label_id
          );
        }
      }
    },
    taskMediaAttachedHandler(data) {
      const { socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const task = this.$api.getEntity("task", data.details.task_id);
        if (task) {
          //newly upload media will not found array (issue)
          const mediaArray = data.details.media.map((id) => {
            return this.$api.getEntity("media", id);
          });
          task.media = [...mediaArray, ...task.media];
        }
      }
    },
    taskMediaDetachedHandler(data) {
      const { socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const task = this.$api.getEntity("task", data.details.task_id);
        if (task) {
          task.media = task.media.filter((m) => m.id !== data.details.media[0]);
        }
      }
    },
    subtaskCreatedHandler(data) {
      const { socket_id: socketId, object: subtask } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const task = this.$api.getEntity("task", subtask.task_id);
        if (task) {
          const owner = this.$api.getEntity("user", subtask.user_id);
          if (subtask.media.length > 0) {
            subtask.media = subtask.media.map((m) => {
              const media = this.$api.getEntity("media", m.id);
              return media;
            });
          }
          subtask.media = subtask.media.map((m) => {
            m.comments = [];
            m.commentPoint = [];
            return m;
          });
          const createdSubtask = {
            owner,
            members: [],
            ...subtask,
          };
          task.subtasks.push(createdSubtask);
          this.$api.updateEntity("subTask", data.object_id, createdSubtask);
        }
      }
    },
    subtaskUpdatedHandler(data) {
      this.activityCreatedHandler(data);
      const { socket_id: socketId, object_id: Id } = data;
      if (socketId !== pusher.connection.socket_id) {
        const subtask = this.$api.getEntity("subTask", Id);
        subtask.title = data.object.title;
        subtask.sequence = data.object.sequence;
        subtask.completed = data.object.completed;
      }
    },
    subtaskDeletedHandler(data) {
      const { socket_id: socketId, object: subtask } = data;
      if (socketId !== pusher.connection.socket_id) {
        const task = this.$api.getEntity("task", subtask.task_id);
        if (task) {
          task.subtasks = task.subtasks.filter((s) => s.id !== subtask.id);
          const notificationsQuery = this.$api.getQuery("NotificationsQuery");
          notificationsQuery.data.notifications =
            notificationsQuery.data.notifications.filter(
              (n) => n.action.object_id !== subtask.id
            );
        }
      }
    },
    subtasksClearedHandler(data) {
      const { socket_id: socketId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const task = this.$api.getEntity("task", data.details.id);
        if (task) {
          task.subtasks = task.subtasks.filter(
            (s) => s.completed !== data.details.completed
          );
        }
      }
    },
    subTaskMemberAttachedHandler(data) {
      const { socket_id: socketId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const subTask = this.$api.getEntity("subTask", data.details.subtask_id);
        if (subTask && subTask.members) {
          const member = this.$api.getEntity("user", data.details.user_id);
          subTask.members.push(member);
        }
      }
    },
    subTaskMemberDetachedHandler(data) {
      const { socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const subTask = this.$api.getEntity("subTask", data.details.subtask_id);
        if (subTask && subTask.members) {
          subTask.members = subTask.members.filter(
            (m) => m.id !== data.details.user_id
          );
        }
      }
    },
    subtaskMediaDetached(data) {
      const { socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const subtask = this.$api.getEntity("subTask", data.details.subtask_id);
        subtask.media = subtask.media.filter(
          (m) => m.id != data.details.media[0]
        );
      }
    },
    taskCommentCreatedHandler(data) {
      const { socket_id: socketId, object: comment } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const taskCommentQuery = this.$api.getQuery(
          `TaskCommentsQuery:${comment.task_id}`
        );
        if (taskCommentQuery.data && taskCommentQuery.data.taskComments) {
          const owner = this.$api.getEntity("user", comment.user_id);
          if (comment.media.length > 0) {
            comment.media = comment.media.map((m) => {
              const media = this.$api.getEntity("media", m.id);
              return media;
            });
          }
          if (comment.shared_task_comment) {
            const sharedComment = taskCommentQuery.data.taskComments.find(
              (c) =>
                c.id === comment.shared_task_comment.original_task_comment_id
            );
            const createdComment = {
              __typename: "taskComment",
              is_bot: null,
              owner,
              sharedTaskComment: sharedComment,
              ...comment,
            };
            this.$api.updateEntity("taskComment", comment.id, createdComment);
            taskCommentQuery.data.taskComments.unshift(createdComment);
          } else {
            const createdComment = {
              __typename: "taskComment",
              is_bot: null,
              owner,
              sharedTaskComment: comment.shared_task_comment,
              ...comment,
            };
            this.$api.updateEntity("taskComment", comment.id, createdComment);
            taskCommentQuery.data.taskComments.unshift(createdComment);
          }
          this.scrollToLatest("#commentScroll");
        }
      }
    },
    taskCommentUpdatedHandler(data) {
      const { socket_id: socketId, object: comment } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const taskCommentQuery = this.$api.getQuery(
          `TaskCommentsQuery:${comment.task_id}`
        );
        if (taskCommentQuery.data && taskCommentQuery.data.taskComments) {
          const owner = this.$api.getEntity("user", comment.user_id);
          const index = taskCommentQuery.data.taskComments.findIndex(
            (c) => c.id === comment.id
          );
          if (comment.media.length > 0) {
            comment.media = comment.media.map((m) => {
              const media = this.$api.getEntity("media", m.id);
              return media;
            });
          }
          if (comment.shared_task_comment) {
            const sharedComment = taskCommentQuery.data.taskComments.find(
              (c) =>
                c.id === comment.shared_task_comment.original_task_comment_id
            );
            const updatedComment = {
              __typename: "taskComment",
              is_bot: null,
              owner,
              sharedTaskComment: sharedComment,
              ...comment,
            };
            taskCommentQuery.data.taskComments.splice(index, 1, {
              ...updatedComment,
            });
            this.$api.updateEntity("taskComment", comment.id, updatedComment);
          } else {
            const updatedComment = {
              __typename: "taskComment",
              is_bot: null,
              owner,
              sharedTaskComment: comment.shared_task_comment,
              ...comment,
            };
            taskCommentQuery.data.taskComments.splice(index, 1, {
              ...updatedComment,
            });
            this.$api.updateEntity("taskComment", comment.id, updatedComment);
          }
        }
      }
    },
    taskCommentDeletedHandler(data) {
      const { socket_id: socketId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const taskCommentQuery = this.$api.getQuery(
          `TaskCommentsQuery:${data.object.task_id}`
        );
        if (taskCommentQuery.data && taskCommentQuery.data.taskComments) {
          taskCommentQuery.data.taskComments =
            taskCommentQuery.data.taskComments.filter((c) => {
              return c.id !== data.object_id;
            });
          const notificationsQuery = this.$api.getQuery("NotificationsQuery");
          notificationsQuery.data.notifications =
            notificationsQuery.data.notifications.filter(
              (n) => n.action.object_id !== data.object_id
            );
        }
      }
    },
    taskCommentReactionAddedHandler(data) {
      const { socket_id: socketId, subject_id: userId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const reaction = this.$api.getEntity(
          "reaction",
          data.details.reaction_id
        );
        const taskComment = this.$api.getEntity("taskComment", data.details.id);
        const user = this.$api.getEntity("user", userId);
        const newReactiondata = { ...taskComment.commentreactiondata };
        if (newReactiondata.hasOwnProperty(reaction.name)) {
          newReactiondata[reaction.name].push({
            first: user.first,
            fullname: user.first + " " + user.last,
            id: user.id,
            is_deleted: null,
            last: user.last,
            pic: user.pic,
            time: getUnixTime(new Date()),
          });
        } else {
          newReactiondata[reaction.name] = [
            {
              first: user.first,
              fullname: user.first + " " + user.last,
              id: user.id,
              is_deleted: null,
              last: user.last,
              pic: user.pic,
              time: getUnixTime(new Date()),
            },
          ];
        }
        const taskCommentQuery = this.$api.getQuery(
          `TaskCommentsQuery:${data.object.task_id}`
        );
        if (taskCommentQuery.data && taskCommentQuery.data.taskComments) {
          taskCommentQuery.data.taskComments.map((c) => {
            if (c.id === taskComment.id) {
              c.commentreactiondata = { ...newReactiondata };
            }
          });
        }
        this.$api.updateEntity("taskComment", taskComment.id, {
          commentreactiondata: newReactiondata,
        });
      }
    },
    taskCommentReactionRemovedHadler(data) {
      const { socket_id: socketId, subject_id: userId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const reaction = this.$api.getEntity(
          "reaction",
          data.details.reaction_id
        );
        const taskComment = this.$api.getEntity("taskComment", data.details.id);
        const newReactiondata = { ...taskComment.commentreactiondata };
        if (newReactiondata[reaction.name].length > 1) {
          let index = newReactiondata[reaction.name].findIndex(
            (a) => a.id === userId
          );
          newReactiondata[reaction.name].splice(index, 1);
        } else {
          delete newReactiondata[reaction.name];
        }
        this.$api.updateEntity("taskComment", taskComment.id, {
          commentreactiondata: newReactiondata,
        });
      }
    },
    taskCommentMediaDetached(data) {
      const { socket_id: socketId } = data;
      if (socketId !== pusher.connection.socket_id) {
        const comment = this.$api.getEntity(
          "taskComment",
          data.details.task_comment_id
        );
        if (comment) {
          const taskCommentQuery = this.$api.getQuery(
            `TaskCommentsQuery:${comment.task_id}`
          );
          if (taskCommentQuery.data && taskCommentQuery.data.taskComments) {
            comment.media = comment.media.filter(
              (m) => m.id !== data.details.media[0]
            );
            const index = taskCommentQuery.data.taskComments.findIndex(
              (c) => c.id === data.details.task_comment_id
            );
            taskCommentQuery.data.taskComments.splice(index, 1, comment);
          }
        }
      }
    },
    customFieldCreatedHandler(data) {
      const { socket_id: socketId, object: customField } = data;
      if (socketId !== pusher.connection.socket_id) {
        const customFieldQuery = this.$api.getQuery(
          `CustomFieldQuery:${data.scope_id}`
        );
        let newField = {
          __typename: "customField",
          id: customField.id,
          details: null,
          icon: null,
          is_active: customField.is_active,
          is_suggested: customField.is_suggested,
          sequence: customField.sequence,
          show_in_task: customField.show_in_task,
          title: customField.title,
          type: customField.type,
          user_id: null,
          workspace_id: customField.workspace_id,
        };
        if (customField.type === "dropdown") {
          newField = {
            ...newField,
            customFieldOption: [...customField.custom_field_option],
          };
          if (customField.custom_field_option.length) {
            customField.custom_field_option.forEach((cfo) => {
              const newOption = {
                __typename: "customFieldOption",
                c_f_id: cfo.c_f_id,
                colorcode: cfo.colorcode,
                id: cfo.id,
                label: cfo.label,
                option: cfo.option,
                sequence: cfo.sequence,
                customFieldOptionSelection: null,
              };
              this.$api.updateEntity("customFieldOption", cfo.id, newOption);
            });
          }
        }
        if (customFieldQuery.data) {
          customFieldQuery.data.customFields = [
            ...customFieldQuery.data.customFields,
            newField,
          ];
        }
        this.$api.updateEntity("customField", customField.id, newField);
      }
      const workspaceFieldsQuery = this.$api.getQuery(
        `WorkspaceFieldsQuery:${data.scope_id}`
      );

      if (workspaceFieldsQuery.data) {
        workspaceFieldsQuery.data.workspaceFields.push(
          customField.workspace_field
        );
      }
    },
    customFieldUpdatedHandler(data) {
      const { socket_id: socketId, object: customField } = data;
      if (socketId !== pusher.connection.socket_id) {
        const customFieldEntity = this.$api.getEntity(
          "customField",
          customField.id
        );
        customFieldEntity.title = customField.title;
        customFieldEntity.show_in_task = customField.show_in_task;
        customFieldEntity.is_active = customField.is_active;
      }
      if (customField && customField.workspace_field) {
        // const workspaceFieldQuery = this.$api.getQuery(
        //     `WorkspaceFieldsQuery:${data.scope_id}`
        // );
        const workspaceFieldEntity = this.workspaceFields.find(
          (c) => c.id === customField.workspace_field.id
        );
        // const workspaceFieldEntity = this.$api.getEntity(
        //   "workspaceField",
        //   customField.workspace_field.id
        // );
        workspaceFieldEntity.title = customField.title;
      }
    },
    customFieldDeletedHandler(data) {
      const { socket_id: socketId, object: customField } = data;
      if (socketId !== pusher.connection.socket_id) {
        const customFieldQuery = this.$api.getQuery(
          `CustomFieldQuery:${data.scope_id}`
        );
        if (customFieldQuery.data) {
          customFieldQuery.data.customFields =
            customFieldQuery.data.customFields.filter(
              (cf) => cf.id !== customField.id
            );
        }
      }
    },
    customFieldOptionCreatedHandler(data) {
      const { socket_id: socketId, object: customFieldOption } = data;
      if (socketId !== pusher.connection.socket_id) {
        const customField = this.$api.getEntity(
          "customField",
          customFieldOption.c_f_id
        );
        const newCFOption = {
          __typename: "customFieldOption",
          c_f_id: customFieldOption.c_f_id,
          colorcode: "#b8c1cb",
          id: customFieldOption.id,
          label: customFieldOption.label,
          option: customFieldOption.option,
          sequence: customFieldOption.sequence,
          customFieldOptionSelection: null,
        };
        customField.customFieldOption = [
          ...customField.customFieldOption,
          newCFOption,
        ];
        this.$api.updateEntity(
          "customFieldOption",
          customFieldOption.id,
          newCFOption
        );
      }
    },
    customFieldOptionUpdatedHandler(data) {
      const { socket_id: socketId, object: customFieldOption } = data;
      if (socketId !== pusher.connection.socket_id) {
        const CFOptionEntity = this.$api.getEntity(
          "customFieldOption",
          customFieldOption.id
        );
        CFOptionEntity.colorcode = customFieldOption.colorcode;
        CFOptionEntity.sequence = customFieldOption.sequence;
      }
    },
    customFieldOptionDeletedHandler(data) {
      const { socket_id: socketId, object: customFieldOption } = data;
      if (socketId !== pusher.connection.socket_id) {
        const customField = this.$api.getEntity(
          "customField",
          customFieldOption.c_f_id
        );
        customField.customFieldOption = customField.customFieldOption.filter(
          (cfo) => cfo.id !== customFieldOption.id
        );
      }
    },
    customFieldOptionSelectionCreatedHandler(data) {
      const { socket_id: socketId, object: CFOSelection } = data;
      if (socketId !== pusher.connection.socket_id) {
        const taskEntity = this.$api.getEntity("task", CFOSelection.task_id);
        const newCFOSelection = {
          __typename: "customFieldOptionSelection",
          c_f_id: CFOSelection.c_f_id,
          c_f_o_id: CFOSelection.c_f_o_id,
          task_id: CFOSelection.task_id,
          id: CFOSelection.id,
          option_text: CFOSelection.option_text,
        };
        taskEntity.customFieldOptionSelection = [
          ...taskEntity.customFieldOptionSelection,
          newCFOSelection,
        ];
      }
    },
    customFieldOptionSelectionUpdatedHandler(data) {
      const { socket_id: socketId, object: CFOSelection } = data;
      if (socketId !== pusher.connection.socket_id) {
        const taskEntity = this.$api.getEntity("task", CFOSelection.task_id);
        const newCFOSelection = {
          __typename: "customFieldOptionSelection",
          c_f_id: CFOSelection.c_f_id,
          c_f_o_id: CFOSelection.c_f_o_id,
          task_id: CFOSelection.task_id,
          id: CFOSelection.id,
          option_text: CFOSelection.option_text,
        };
        taskEntity.customFieldOptionSelection =
          taskEntity.customFieldOptionSelection.filter(
            (cfos) => cfos.c_f_id !== CFOSelection.c_f_id
          );
        taskEntity.customFieldOptionSelection = [
          ...taskEntity.customFieldOptionSelection,
          newCFOSelection,
        ];
      }
    },
    mediaCreatedHandler(data) {
      const { socket_id: socketId, object: media } = data;
      this.activityCreatedHandler(data);
      if (
        (socketId && socketId !== pusher.connection.socket_id) ||
        media.isFromMail
      ) {
        const query = this.$api.getQuery(
          `MediaQuery:${Number(media.workspace_id)}`
        );
        if (query.data) {
          const createdMedia = {
            commentPoint: [],
            comments: [],
            ...media,
          };
          this.$api.updateEntity("media", media.id, createdMedia);
          query.data.newMedia.unshift(createdMedia);
        } else {
          const createdMedia = {
            commentPoint: [],
            comments: [],
            ...media,
          };
          this.$api.updateEntity("media", media.id, createdMedia);
        }
      }
    },
    mediaUpdatedHandler(data) {
      const { socket_id: socketId, object: media } = data;
      this.activityCreatedHandler(data);
      if (socketId && socketId !== pusher.connection.socket_id) {
        const query = this.$api.getQuery(
          `MediaQuery:${Number(media.workspace_id)}`
        );
        if (query.data) {
          const createdMedia = {
            commentPoint: [],
            comments: [],
            ...media,
          };
          this.$api.updateEntity("media", media.id, createdMedia);
          query.data.newMedia.unshift(createdMedia);
        } else {
          const createdMedia = {
            commentPoint: [],
            comments: [],
            ...media,
          };
          this.$api.updateEntity("media", media.id, createdMedia);
        }
      }
    },
    mediaDeletedHandler(data) {
      const { socket_id: socketId, object: media, details } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        if (details) {
          const query = this.$api.getQuery(`MediaQuery:${data.scope_id}`);
          if (query.data) {
            query.data.newMedia = query.data.newMedia.filter(
              (m) => m.id !== details.id
            );
          }
        } else {
          const query = this.$api.getQuery(`MediaQuery:${data.scope_id}`);
          if (query.data) {
            query.data.newMedia = query.data.newMedia.filter(
              (m) => m.id !== media.id
            );
          }
        }
      }
    },
    mediaFolderCreatedHandler(data) {
      const { socket_id: socketId, object: mediaFolder } = data;
      this.activityCreatedHandler(data);
      if (socketId && socketId !== pusher.connection.socket_id) {
        const query = this.$api.getQuery(
          `MediaFoldersQuery:${Number(mediaFolder.workspace_id)}`
        );
        if (query.data) {
          this.$api.updateEntity("mediaFolder", mediaFolder.id, mediaFolder);
          query.data.mediaFolders.unshift(mediaFolder);
        } else {
          this.$api.updateEntity("mediaFolder", mediaFolder.id, mediaFolder);
        }
      }
    },
    mediaFolderDeletedHandler(data) {
      const { socket_id: socketId, object: mediaFolder, details } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        if (details) {
          const query = this.$api.getQuery(
            `MediaFoldersQuery:${data.scope_id}`
          );
          if (query.data) {
            query.data.mediaFolders = query.data.mediaFolders.filter(
              (m) => m.id !== details.id
            );
          }
        } else {
          const query = this.$api.getQuery(
            `MediaFoldersQuery:${data.scope_id}`
          );
          if (query.data) {
            query.data.mediaFolders = query.data.mediaFolders.filter(
              (m) => m.id !== mediaFolder.id
            );
          }
        }
      }
    },
    mediaFolderUpdateddHandler(data) {
      const { object: mediaFolder, socket_id: socketId } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const query = this.$api.getQuery(
          `MediaFoldersQuery:${Number(mediaFolder.workspace_id)}`
        );
        if (query.data) {
          this.$api.updateEntity("mediaFolder", mediaFolder.id, mediaFolder);
          query.data.mediaFolders.unshift(mediaFolder);
        } else {
          this.$api.updateEntity("mediaFolder", mediaFolder.id, mediaFolder);
        }
      }
    },
    mediaCommentPointCreatedHandler(data) {
      const { socket_id: socketId, object: mediaCommentPoint } = data;
      if (socketId !== pusher.connection.socket_id) {
        const media = this.$api.getEntity("media", mediaCommentPoint.media_id);
        if (media) {
          media.commentPoint.push(mediaCommentPoint);
        }
      }
    },
    mediaCommentPointUpdatedHandler(data) {
      const { socket_id: socketId, object: mediaCommentPoint } = data;
      if (socketId !== pusher.connection.socket_id) {
        const media = this.$api.getEntity("media", mediaCommentPoint.media_id);
        if (media) {
          const index = media.commentPoint.findIndex(
            (c) => c.id === mediaCommentPoint.id
          );
          media.commentPoint.splice(index, 1, { ...mediaCommentPoint });
        }
      }
    },
    mediaCommentPointDeletedHandler(data) {
      const { socket_id: socketId, object: mediaCommentPoint } = data;
      if (socketId !== pusher.connection.socket_id) {
        const media = this.$api.getEntity("media", mediaCommentPoint.media_id);
        if (media) {
          media.commentPoint = media.commentPoint.filter(
            (c) => c.id !== mediaCommentPoint.id
          );
        }
      }
    },
    mediaCommentCreatedHandler(data) {
      const { socket_id: socketId, object: mediaComment } = data;
      this.activityCreatedHandler(data);
      if (socketId !== pusher.connection.socket_id) {
        const media = this.$api.getEntity("media", mediaComment.media_id);
        if (media) {
          const owner = this.$api.getEntity("user", mediaComment.user_id);
          const comment = {
            owner,
            ...mediaComment,
          };
          media.comments.push(comment);
          this.$api.updateEntity("mediaComment", mediaComment.id, comment);
        }
      }
    },
    mediaCommentUpdatedHandler(data) {
      const { socket_id: socketId, object: mediaComment } = data;
      if (socketId !== pusher.connection.socket_id) {
        const mediaCommentEntity = this.$api.getEntity(
          "mediaComment",
          mediaComment.id
        );
        mediaCommentEntity.comment = mediaComment.comment;
        mediaCommentEntity.updated_at = mediaComment.updated_at;
      }
    },
    mediaCommentDeletedHandler(data) {
      const { socket_id: socketId, object: mediaComment } = data;
      if (socketId !== pusher.connection.socket_id) {
        const media = this.$api.getEntity("media", mediaComment.media_id);
        media.comments = media.comments.filter((c) => c.id !== mediaComment.id);
      }
    },
    async updateWorkspace(workspaceId) {
      this.subscribeWorkspaceChannel(workspaceId);
      // const workspace = this.$api.getEntity("workspace", workspaceId);
      // this.workspaces.push(workspace);
    },
    async updateCompanyInformation(company, companyUri) {
      const companies = {
        ...this.companies,
      };
      companies[companyUri].companyName = company.name;
      companies[companyUri].logo = company.logo;
      this.$store.dispatch("resetCompanies", companies);
    },
    scrollToLatest(elementId) {
      setTimeout(() => {
        if (document.querySelector(elementId)) {
          document.querySelector(elementId).scrollTop =
            document.querySelector(elementId).scrollHeight;
        }
      }, 10);
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
  },
  watch: {
    $route() {
      this.$store.dispatch("setLastRoute", this.$route);
    },
  },
};
</script>
