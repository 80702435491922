const soundFile = require("@/assets/notification.wav");

export default {
  data() {
    return {
      noficationMoz: null,
      sound: null,
    };
  },
  mounted() {
    this.sound = new Audio(soundFile);
  },
  methods: {
    notificationMessageHandler(notification) {
      const action = notification.action;
      const subject = notification.action.subject;
      const object = notification.action.object;
      const scope = notification.action.scope;
      let title = null;
      let text = null;
      switch (action.type) {
        case "message-created":
          title = subject.fullname;
          text = `Sent ${object.content.substring(0, 25)}... in the ${
            scope.title
          }`;
          break;
        case "task-created":
          title = scope.title;
          text = `Create new task ${object.title} in the ${scope.title}`;
          break;
        case "message-reaction-added":
          title = subject.fullname;
          text = `${title} reacted on a ${object.content.substring(
            0,
            10
          )}... in the ${scope.title}`;
          break;
        case "task-member-attached":
          title = subject.fullname;
          text = `Added You to the ${object.title} in the ${scope.title}`;
          break;
        case "subtask-member-attached": {
          title = subject.fullname;
          const taskTicket = `T${scope.title.substring(0, 2).toUpperCase()}-${
            object.task_id
          }`;
          const subtaskTicket = `S${scope.title
            .substring(0, 2)
            .toUpperCase()}-${object.id}`;
          text = `Added You to the Task ${taskTicket} Subtask${subtaskTicket} in the ${scope.title}`;
          break;
        }
        case "task-comment-created":
          title = subject.fullname;
          text = `Mentioned You in a task in the ${object.task.title} Task in the ${scope.title}`;
          break;
        case "task-comment-reaction-added":
          title = subject.fullname;
          text = `${title} reacted on a ${object.comment.substring(
            0,
            10
          )}... in the ${scope.title}`;
          break;
        default:
          break;
      }
      if (title !== null && text !== null)
        this.notifyMe(title, text, action.type);
    },
    notifyMe(title, text, actionType) {
      if (this.user && this.user.web_notification) {
        if (!("Notification" in window)) {
          alert("This browser does not support system notifications");
        } else if (Notification.permission === "granted") {
          this.noficationMoz = new Notification(title, { body: text });
          if (
            actionType === "message-created" ||
            actionType === "message-reaction-added" ||
            actionType === "task-comment-created" ||
            actionType === "task-member-attached" ||
            actionType === "subtask-member-attached" ||
            actionType === "task-comment-reaction-added"
          ) {
            this.sound.currentTime = 0;
            this.sound.play();
          }
        } else if (Notification.permission !== "denied") {
          Notification.requestPermission(function(permission) {
            if (permission === "granted") {
              this.noficationMoz = new Notification(title, { body: text });
            }
          });
        }
      }
    },
  },
};
